<template>
	<div class="PurchaseFirm">
		<w-navTab :titleText="detail.name"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="header">
				<div class="header-top">
					<div class="header-img">
						<img :src="detail.image" alt="" />
					</div>
					<div class="header-top-content">
						<div class="header-title">{{detail.name}}</div>
						<div class="header-top-bottom" @click="isPopupShow = true">
							<div class="header-text">
								{{detail.intro}}
							</div>
							<van-icon name="arrow" color="#666" size="16" />
						</div>
					</div>
					<div class="header-top-button">
					</div>
				</div>

				<div class="header-bottom">
					<div class="header-content" v-if="type == 0">
						<div class="header-content-item" @click="callPhone">
							<div class="header-item-text">联系电话：{{detail.phone_number}}</div>
							<div class="header-item-icon">
								<img src="@/assets/img/icon100.png" alt="" />
							</div>
						</div>
						<div class="header-content-item">
							<div class="header-item-text">对公账户：{{detail.corporate_account}}</div>
						</div>
						<div class="header-content-item">
							<div class="header-item-text">开户行：{{detail.bank_name}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="body">
				<div class="body-title">商品列表</div>
				<div class="body-list">
					<div class="body-list-item" v-for="item in purchaseList" @click="toDetail(item)">
						<div class="list-item-img">
							<img :src="item.image_cover" alt="" />
						</div>
						<div class="list-item-content">
							<div class="list-content-title">{{item.name}}</div>
							
							<div class="list-content-bottom">
								<div class="list-content-text">
									<span>￥</span>{{item.price}}
								</div>
								<div class="list-content-icon">
									<img src="@/assets/img/test29.png" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<van-popup round closeable position="bottom" v-model="isPopupShow" >
			<div class="popup-content">
				<div class="popup-content-title">公司简介</div>
				<div class="popup-content-text">
					{{detail.intro}}
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import Purchase from '@/api/purchase';
	import VantVendor from '@/vendor/vant';
	import LoadingPage from '@/components/LoadingPage';

	export default {
		name: 'PurchaseFirm',
		data() {
			return {
				isPopupShow: false,
				loadingPage: true,
				detail: '',
				loading: true,
				finished: false,
				isEmpty: false,
				paramsPage: 1,
				paramsLimit: 10,
				companiesList: [],
				purchaseList: [],
				type: 0
			}
		},
		created() {
			this.type = this.$route.params.type
			this.id = this.$route.params.id
			this.getDetail()
			this.onLoad()
		},
		methods: {
			callPhone() {
				window.location.href = 'tel://' + this.detail.phone_number;
			},
			getDetail() {
				Purchase.companiesDetail({
						companies_id: this.id
					})
					.then(result => {
						this.detail = result.data
						this.loadingPage = false
					})
					.catch(error => {
						console.log(error);
					})
			},
			onLoad() {
				this.loading = true;

				let params = {
					companies_id: this.id,
					page: this.paramsPage,
					limit: this.paramsLimit
				}
				Purchase.productsList(params)
					.then(result => {
						this.paramsPage++;
						this.purchaseList = this.purchaseList.concat(result.data.list);

						this.loading = false;

						if (this.purchaseList.length <= 0) {
							this.isEmpty = true;
						}

						if (this.purchaseList.length >= result.data.total) {
							this.finished = true;
						}
					})
					.catch(error => {
						console.log(error);
					});

			},
			toDetail(item) {
				if (this.type == 0) {
					this.$router.push({
						name: 'PurchaseDetail',
						params: {
							id: item.id
						}
					})
				} else {
					window.location.href = item.redirect
				}
			}
		},
		components: {
			VantVendor,
			LoadingPage,
		}
	}
</script>

<style scoped lang="less">
	.PurchaseFirm {
		width: 100%;
		min-height: 100vh;
		background: #F2F2F2;

		.container {
			.header {

				.header-top {
					display: flex;
					align-items: center;
					background-color: #fff;
					padding: 16px;
					box-sizing: border-box;

					.header-img {
						width: 90px;
						height: 90px;
						min-width: 90px;
						margin-right: 12px;
						border-radius: 4px;
						border: 1px solid #E6E6E6;
						box-sizing: border-box;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.header-top-content {
						flex: 1;
						
						.header-title {
							color: #37F;
							font-size: 18px;
							line-height: 27px;
							margin-bottom: 8px;
						}
						.header-top-bottom{
							display: flex;
							align-items: center;
							
							.header-text {
								color: #999;
								font-size: 15px;
								line-height: 23px;
								margin-right: 9px;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
							}
						}
						
					}
					
				}

				.header-bottom {
					padding: 0 16px 14px;
					background: #FFF;
					box-sizing: border-box;

					.header-content {
						border-radius: 8px;
						background: #F9F9F9;
						padding: 12px;
						box-sizing: border-box;

						.header-content-item {
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-bottom: 8px;

							&.header-content-item:last-child {
								margin-bottom: 0;
							}

							.header-item-text {
								color: #333;
								font-size: 16px;
								line-height: 24px;
							}

							.header-item-icon {
								width: 24px;
								height: 24px;
								min-width: 24px;
								margin-right: 13px;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}
						}
					}
				}
			}

			.body {
				padding: 24px 16px;
				box-sizing: border-box;

				.body-title {
					box-sizing: border-box;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #333;
					font-size: 18px;
					line-height: 27px;

					&:before {
						content: '';
						display: inline-block;
						width: 28px;
						height: 1px;
						background: #BEBDC2;
						margin: 0 8px;
					}

					&:after {
						content: '';
						display: inline-block;
						width: 28px;
						height: 1px;
						background: #BEBDC2;
						margin: 0 8px;
					}
				}

				.body-list {
					margin-top: 12px;

					.body-list-item {
						padding: 12px;
						box-sizing: border-box;
						border-radius: 8px;
						background: #FFF;
						margin-bottom: 12px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						&.body-list-item:last-child {
							margin-bottom: 0;
						}

						.list-item-img {
							width: 88px;
							height: 88px;
							min-width: 88px;
							border-radius: 2px;
							border: 1px solid #E6E6E6;
							box-sizing: border-box;

							img {
								width: 100%;
								height: 100%;
								border-radius: 2px;
								object-fit: cover;
							}
						}

						.list-item-content {
							flex: 1;
							margin-left: 17px;

							.list-content-title {
								color: #333;
								font-size: 16px;
								line-height: 24px;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								height: 48px;
							}
							
							.list-content-bottom{
								display: flex;
								align-items: center;
								justify-content: space-between;
								margin-top: 8px;
								
								.list-content-text {
									color: #FF6969;
									font-size: 22px;
									line-height: 32px;
								
									span {
										font-size: 12px;
										line-height: 18px;
									}
								}
								.list-content-icon{
									width: 28px;
									height: 28px;
									
									img{
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}
							}
							
						}
					}
				}
			}
		}
	
		.popup-content{
			padding: 16px 16px 24px;
			box-sizing: border-box;
			
			.popup-content-title{
				color: #333;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				margin-bottom: 16px;
			}
			.popup-content-text{
				color: #666;
				font-size: 16px;
				line-height: 24px;
				text-indent: 30px;
			}
		}
	}
</style>